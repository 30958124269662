interface ChangedFields<T> {
  [key: string]: T;
}

export function diffObjects<T>(obj1: T, obj2: T): ChangedFields<T[keyof T]> {
  const changedFields: ChangedFields<T[keyof T]> = {};

  for (const key in obj1) {
    if (obj1[key] !== obj2[key]) {
      changedFields[key] = obj2[key];
    }
  }

  return changedFields;
}
