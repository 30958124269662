const tipoBolsaCuidados = [
  {
    id: "AMPLIACION_HORARIOS",
    nombre: "Ampliación de horario escolar (mañana o tarde)",
  },
  {
    id: "DIAS_NO_LECTIVOS",
    nombre: "Días no lectivos (fuera de periodos de vacaciones escolares)",
  },
  {
    id: "VACACIONES_ESCOLARES",
    nombre: "Vacaciones escolares (verano, Navidad, Semana Santa, etc.)",
  },
  {
    id: "FIN_DE_SEMANA",
    nombre: "Fin de semana",
  },
  {
    id: "CUIDADOS_DOMICILIO",
    nombre: "Cuidados en domicilio",
  },
  {
    id: "OTROS",
    nombre: "Otros (especificar cuáles)",
  },
];

export default tipoBolsaCuidados;
