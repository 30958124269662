
import comarcas from "@/core/data/comarcas";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Page from "@/layout/Page.vue";
import { AuthModule, ProyectosN2ListadoModule } from "@/store/modules";
import ProyectoN2BolsaCuidados from "@/views/proyectosN2/ProyectosN2DetalleTabs/ProyectoN2BolsaCuidados.vue";
import ProyectoN2DatosGenerales from "@/views/proyectosN2/ProyectosN2DetalleTabs/ProyectoN2DatosGenerales.vue";
import ProyectoN2Empleo from "@/views/proyectosN2/ProyectosN2DetalleTabs/ProyectoN2Empleo.vue";
import ProyectoN2Formacion from "@/views/proyectosN2/ProyectosN2DetalleTabs/ProyectoN2Formacion.vue";
import ProyectoN2ServiciosColectivos from "@/views/proyectosN2/ProyectosN2DetalleTabs/ProyectoN2ServiciosColectivos.vue";
import ProyectoN2ServiciosIndividuales from "@/views/proyectosN2/ProyectosN2DetalleTabs/ProyectoN2ServiciosIndividuales.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { computed, defineComponent, onBeforeMount, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { getModule } from "vuex-module-decorators";

export default defineComponent({
  name: "proyectoN2-detalle",
  components: {
    Page,
    ProyectoN2DatosGenerales,
    ProyectoN2BolsaCuidados,
    ProyectoN2Empleo,
    ProyectoN2Formacion,
    ProyectoN2ServiciosColectivos,
    ProyectoN2ServiciosIndividuales,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const token = route.params.id as string;
    const debug = route.query.debug as string;
    const store = useStore();

    const module = getModule(ProyectosN2ListadoModule, store);
    const authModule = getModule(AuthModule, store);
    const comarcaId = authModule.user.comarcaId;

    const proyectoN2 = computed(() => module.proyectoN2);
    const sincronizado = computed(() => {
      return false; // TODO: por el momento se oculta la sincronización
      // Se opta por mantener siempre habilitado el botón. Que esté sincronizado implica que no ha cambiado ningún dato de ninguna de las pestañas, ni de ninguno de los servicios asociados.
      /*if (module.proyectoN2) {
        return (
          module.proyectoN2.creacionEmpleo?.modifiedOn <
            module.proyectoN2.proyectoSincronizacion?.sincronizadoen ||
          module.proyectoN2.planFormacion?.modifiedOn <
            module.proyectoN2.proyectoSincronizacion?.sincronizadoen ||
          module.proyectoN2.bolsaCuidados?.modifiedOn <
            module.proyectoN2.proyectoSincronizacion?.sincronizadoen ||
          module.proyectoN2.modifiedOn <
            module.proyectoN2.proyectoSincronizacion?.sincronizadoen ||
          module.proyectoN2.proyectoSincronizacion?.sincronizadoen !== undefined
        );
      } else {
        return false;
      }*/
    });

    const cargaDetalle = ref<boolean>(false);

    onBeforeMount(async () => {
      cargaDetalle.value = true;
      try {
        await module.fetchById({ comarcaId: comarcaId, id: token as string });
      } catch (err) {
        Swal.fire({
          text: t("formularios.profesionalDetalle.cargar.error"),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("dialogs.okButton"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      } finally {
        cargaDetalle.value = false;
      }
    });

    watch(proyectoN2, () => {
      if (!proyectoN2.value) return;

      setCurrentPageBreadcrumbs([proyectoN2.value?.denominacion].join(" "), [
        t("menu.planCorresponsables"),
        { title: t("menu.proyectosNv2"), path: "/listados/proyectosN2" },
      ]);
    });

    /*const eliminar = async () => {
      if (!proyectoN2Id) return;

      const { isConfirmed } = await Swal.fire({
        text: t("dialogs.eliminar.confirmacion"),
        icon: "question",
        showCancelButton: true,
        confirmButtonText: t("dialogs.okButton"),
        cancelButtonText: t("dialogs.cancelButton"),
        reverseButtons: true,
      });

      if (!isConfirmed) return;

      try {
        await module.deleteById(comarcaId, token);
        ElMessage({
          message: t("dialogs.eliminar.exito"),
          type: "success",
        });
        router.replace("/listados/proyectosN2");
      } catch (err) {
        Swal.fire({
          text: t("dialogs.eliminar.error"),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("dialogs.okButton"),
          customClass: { confirmButton: "btn btn-primary" },
        });
      }
    };*/

    const sincronizarHub = async () => {
      const proyectoSincronizado = await module.sincronizar({
        proyectoId: module.proyectoN2?.id as string,
        comarcaId: comarcaId,
      });
      if (module.errors) {
        const mensaje = (await module.errors.error.message)
          ? t("dialogs.sincronizarProyecto.error") +
            " " +
            t(module.errors.error.message)
          : t("dialogs.sincronizarProyecto.error");
        Swal.fire({
          text: mensaje,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("dialogs.okButton"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      }
      if (proyectoSincronizado && module.proyectoN2) {
        module.proyectoN2.codigo = proyectoSincronizado.data;
        await Swal.fire({
          text: t("dialogs.sincronizarProyecto.success"),
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: t("dialogs.okButton"),
          heightAuto: false,
          customClass: {
            confirmButton: "btn fw-semobold btn-light-primary",
          },
        });
      }
    };

    return {
      //eliminar,
      isAdmin: !comarcaId,
      comarcas,
      sincronizarHub,
      token,
      sincronizado,
      debug,
    };
  },
});
